<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('feedbacks.title')"
      :config="config"
      @delete="handleDelete"
    />
  </div>
</template>

<script>
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import AuthApi from '@/api/auth'
import FeedbackTableConfig from './config/feedback-table-config'

export default {
  components: {
    OkTable,
  },
  data() {
    return {
      config: FeedbackTableConfig,
    }
  },
  methods: {
    refreshTable() {
      this.$refs.datatable.loadItems()
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => AuthApi.deleteFeedback(data.row.id)
          .then(res => {
            this.$notifySuccess(res.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },
}
</script>
