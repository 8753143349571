import OkTableConfigType from '@/@bya3/components/tables/ok-table/OkTableConfigType'
import { API_URL } from '@/constants/config'
import LocalizationService from '@/services/localization-service'

const FeedbackTableConfig: OkTableConfigType = {
  mode: 'remote',
  url: `${API_URL}/user/feedbacks`,
  rowsPath: 'feedbacks',
  rowClickable: true,
  attachPageId: false,
  columns: [
    {
      label: 'fields.phone-number',
      field: 'phone',
    },
    {
      label: 'fields.branch',
      field: 'branch',
      formatFn: (branch: any) => LocalizationService.getTranslateArEn(branch),
    },
    {
      label: 'feedbacks.rate',
      field: 'rate',
      formatFn: (rate: any) => `${rate}/5`,
    },
    {
      label: 'feedbacks.note',
      field: 'note',
    },
    {
      label: 'fields.created-at',
      field: 'created_at',
    },
  ],
  paginationOptions: {
    enabled: true,
  },
  sortOptions: {
    enabled: false,
  },
  actions: [
    {
      eventName: 'delete',
      icon: 'Trash2Icon',
      variant: 'danger',
    },
  ],
  filters: [
  ],
}

export default FeedbackTableConfig
